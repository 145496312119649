/* eslint-disable max-len */
import React from "react";
import { ISVG } from "./constants/ISVG";
import { SVGIconColors } from "./constants/SVGIconColors";

const RolloversIcon: React.FC<ISVG> = (props) => {
  const {
    style = {},
    fill = SVGIconColors.pink,
    width = "39",
    className = "",
    height = "34",
    viewBox = "0 0 39 34",
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fill}
        d="M19.38 33.706c-9.157 0-16.573-7.484-16.573-16.725h1.665c0 8.286 6.697 15.045 14.907 15.045 3.959 0 7.786-1.6 10.555-4.425.323-.33.85-.366 1.196-.06a.8.8 0 01.056 1.16c-3.103 3.177-7.354 5.005-11.807 5.005zM35.914 16.98h-1.665c0-8.285-6.697-15.044-14.908-15.044-4.229.036-8.224 1.824-11.045 5.015a.86.86 0 01-1.18.118A.794.794 0 017 5.926 16.34 16.34 0 0119.38.294c9.118 0 16.572 7.484 16.572 16.687h-.038z"
      ></path>
      <path
        fill={fill}
        d="M36.703 16.11a2 2 0 01-3.164.32l-1.694-1.809 1.22-1.118 2 2.142 1.573-2.535 1.378.861-1.313 2.138zM2.287 20.973l-1.465-.77 1.18-2.213a2 2 0 013.139-.512l1.801 1.702-1.15 1.19-2.125-2.017-1.38 2.62z"
      ></path>
    </svg>
  );
};

export default RolloversIcon;
