/* eslint-disable max-len */
import React from "react";
import { ISVG } from "./constants/ISVG";
import { SVGIconColors } from "./constants/SVGIconColors";

const DocumentDownloadIcon: React.FC<ISVG> = (props) => {
  const {
    style = {},
    fill = SVGIconColors.pink,
    width = "34",
    className = "",
    height = "34",
    viewBox = "0 0 34 34",
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M29.6562 21.2188V26.8438C29.6562 27.5897 29.3599 28.305 28.8325 28.8325C28.305 29.3599 27.5897 29.6562 26.8438 29.6562H7.15625C6.41033 29.6562 5.69496 29.3599 5.16751 28.8325C4.64007 28.305 4.34375 27.5897 4.34375 26.8438V21.2188"
        stroke={fill}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.9682 14.1875L16.9995 21.2187L24.0307 14.1875"
        stroke={fill}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17 21.2188V4.34375" stroke={fill} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DocumentDownloadIcon;
