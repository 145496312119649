/* eslint-disable max-len */
import React from "react";
import { ISVG } from "./constants/ISVG";
import { SVGIconColors } from "./constants/SVGIconColors";

const PersonIcon: React.FC<ISVG> = (props) => {
  const {
    style = {},
    fill = SVGIconColors.pink,
    width = "39",
    className = "",
    height = "38",
    viewBox = "0 0 39 38",
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fill}
        d="M18.67 20.177c-5.418 0-9.883-4.433-9.883-9.883A9.875 9.875 0 0118.669.412a9.875 9.875 0 019.882 9.882 9.875 9.875 0 01-9.882 9.883zm0-17.19c-3.99 0-7.264 3.294-7.264 7.307s3.274 7.307 7.263 7.307 7.263-3.294 7.263-7.307-3.274-7.307-7.263-7.307z"
      ></path>
      <path
        fill={fill}
        d="M38.434 37.588H.316v-1.253c0-10.195 8.562-18.512 19.059-18.512 10.497 0 19.059 8.317 19.059 18.512v1.253zM2.896 35.082h32.957c-.645-8.259-7.799-14.81-16.478-14.81-8.68 0-15.775 6.551-16.42 14.81h-.059z"
      ></path>
    </svg>
  );
};

export default PersonIcon;
