/* eslint-disable max-len */
import React from "react";
import { ISVG } from "./constants/ISVG";
import { SVGIconColors } from "./constants/SVGIconColors";

const WatchIcon: React.FC<ISVG> = (props) => {
  const {
    style = {},
    fill = SVGIconColors.pink,
    width = "40",
    className = "",
    height = "28",
    viewBox = "0 0 40 28",
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fill}
        d="M18.332 16.845h-3.366a.776.776 0 01-.79-.788c0-.446.344-.789.79-.789h3.366a1.977 1.977 0 001.958-1.954c0-.445.343-.788.79-.788h1.82c1.305 0 2.404-.891 2.747-2.09h-8.242a.776.776 0 01-.79-.79c0-.445.343-.788.79-.788h9.135c.447 0 .79.343.79.789a4.473 4.473 0 01-4.465 4.456h-1.133a3.5 3.5 0 01-3.434 2.742h.034zM10.296 27.882a2.55 2.55 0 01-1.82-.754L.85 19.518a2.566 2.566 0 010-3.633l1.717-1.714a2.595 2.595 0 013.64 0l7.625 7.61c.996.994.996 2.64 0 3.633l-1.717 1.714a2.55 2.55 0 01-1.82.754zm-.687-1.85c.378.376 1.03.376 1.408 0l1.717-1.715a1.013 1.013 0 000-1.405l-7.659-7.61a1.018 1.018 0 00-1.408 0L1.95 17.017a1.018 1.018 0 00-.275.686c0 .24.103.514.275.685l7.624 7.61.035.034z"
      ></path>
      <path
        fill={fill}
        d="M5.66 15.543a.684.684 0 01-.55-.24.806.806 0 010-1.132L15.24 4.06s.344-.24.55-.24h7.383c.447 0 .79.342.79.788a.776.776 0 01-.79.788H16.1l-9.891 9.907s-.344.24-.55.24zM14.52 22.775a.684.684 0 01-.55-.24.806.806 0 010-1.131 5.569 5.569 0 013.95-1.645c1.717 0 3.331-.652 4.533-1.886l2.404-2.4a.81.81 0 011.134 0c.309.31.309.824 0 1.132l-2.404 2.4a7.984 7.984 0 01-5.667 2.33 4.031 4.031 0 00-2.85 1.166.811.811 0 01-.55.24v.034z"
      ></path>
      <path
        fill={fill}
        d="M30.49 18.319a9.082 9.082 0 01-7.659-4.182.783.783 0 01.24-1.097.786.786 0 011.1.24 7.57 7.57 0 006.353 3.462c4.155 0 7.556-3.359 7.556-7.507 0-4.147-3.435-7.54-7.59-7.54a7.52 7.52 0 00-7.522 7.54.776.776 0 01-.79.789.776.776 0 01-.79-.789c0-5.004 4.087-9.117 9.136-9.117 5.048 0 9.135 4.079 9.135 9.117 0 5.04-4.087 9.118-9.135 9.118l-.034-.034zM13.627 23.666a.684.684 0 01-.55-.24.806.806 0 010-1.13l.893-.892a.81.81 0 011.134 0c.309.308.309.823 0 1.131l-.893.891s-.344.24-.55.24h-.034z"
      ></path>
    </svg>
  );
};

export default WatchIcon;
