/* eslint-disable max-len */
import React from "react";
import { ISVG } from "./constants/ISVG";
import { SVGIconColors } from "./constants/SVGIconColors";

const FluctuateGraphIcon: React.FC<ISVG> = (props) => {
  const {
    style = {},
    fill = SVGIconColors.pink,
    width = "40",
    className = "",
    height = "38",
    viewBox = "0 0 40 38",
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fill}
        d="M37.653 37.588h-8.654a.765.765 0 01-.774-.78V23.531c0-.203.1-.407.236-.543l8.182-8.046s.336-.238.538-.238h.472c.437 0 .774.34.774.781v21.287c0 .442-.337.781-.774.781v.034zm-7.88-1.562h7.105V16.878l-7.104 6.96v12.189zM12.164 37.588H3.645a.765.765 0 01-.774-.78V22.954c0-.237.1-.475.303-.61L7.45 19.05a.804.804 0 011.01.034l4.276 4.04s.236.34.236.577v13.105c0 .442-.337.781-.774.781h-.034zm-7.778-1.562h7.004V24.042l-3.536-3.328-3.434 2.649v12.663h-.034zM24.892 37.588h-8.654a.765.765 0 01-.774-.78V23.701c0-.305.168-.577.471-.713.27-.136.606-.068.842.136l3.771 3.565 3.805-3.735c.235-.203.572-.271.842-.17a.768.768 0 01.471.713v13.275c0 .442-.337.781-.774.781v.034zm-7.88-1.562h7.105V25.4l-2.997 2.954a.748.748 0 01-1.077 0l-3.03-2.852v10.524zM20.582 20.137a.821.821 0 01-.539-.203L7.854 8.322l-6.263 4.821c-.336.272-.841.204-1.077-.135-.27-.34-.202-.85.135-1.087L7.416 6.66a.765.765 0 011.01.068L20.583 18.27 36.44 2.686a.788.788 0 011.11 0 .804.804 0 010 1.12L21.122 19.9s-.337.237-.54.237z"
      ></path>
      <path
        fill={fill}
        d="M38.865 4.825l.976-3.429c.169-.577-.37-1.12-.943-.984l-3.434.883c-.572.135-.774.882-.37 1.324l2.458 2.546c.404.441 1.145.238 1.313-.34z"
      ></path>
    </svg>
  );
};

export default FluctuateGraphIcon;
