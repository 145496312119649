/* eslint-disable max-len */
import React from "react";
import { ISVG } from "./constants/ISVG";
import { SVGIconColors } from "./constants/SVGIconColors";

const TransactionIcon: React.FC<ISVG> = (props) => {
  const {
    style = {},
    fill = SVGIconColors.pink,
    width = "41",
    className = "",
    height = "38",
    viewBox = "0 0 41 38",
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fill}
        d="M39.35 5.754H14.595a.772.772 0 01-.775-.769c0-.425.347-.769.775-.769H39.33c.429 0 .776.344.776.77a.772.772 0 01-.776.768h.02zM5.208 9.58C2.678 9.58.596 7.514.596 4.984S2.677.412 5.208.412c2.53 0 4.612 2.064 4.612 4.573 0 2.51-2.082 4.574-4.612 4.574v.02zm0-7.63c-1.694 0-3.082 1.376-3.082 3.056 0 1.68 1.388 3.056 3.082 3.056s3.081-1.377 3.081-3.056c0-1.68-1.387-3.056-3.081-3.056zM39.35 19.759H14.595a.772.772 0 01-.775-.77c0-.424.347-.768.775-.768H39.33c.429 0 .776.344.776.769a.772.772 0 01-.776.769h.02zM5.208 23.584c-2.551 0-4.612-2.064-4.612-4.574s2.081-4.574 4.612-4.574c2.53 0 4.612 2.065 4.612 4.574 0 2.51-2.082 4.574-4.612 4.574zm0-7.63c-1.694 0-3.082 1.376-3.082 3.056 0 1.68 1.388 3.056 3.082 3.056s3.081-1.376 3.081-3.056c0-1.68-1.387-3.056-3.081-3.056zM39.35 33.763H14.595a.772.772 0 01-.775-.769c0-.425.347-.769.775-.769H39.33c.429 0 .776.344.776.77a.772.772 0 01-.776.768h.02zM5.208 37.588c-2.551 0-4.612-2.064-4.612-4.574 0-2.509 2.081-4.573 4.612-4.573 2.53 0 4.612 2.064 4.612 4.573 0 2.51-2.082 4.574-4.612 4.574zm0-7.63c-1.694 0-3.082 1.377-3.082 3.056 0 1.68 1.388 3.056 3.082 3.056s3.081-1.376 3.081-3.056c0-1.68-1.387-3.055-3.081-3.055z"
      ></path>
    </svg>
  );
};

export default TransactionIcon;
