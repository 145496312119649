import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Modal, { IModalProps } from "../modal";

import style from "./loadingIndicatorModal.module.css";

interface ILoadingIndicatorModalProps extends IModalProps {
  loadingText?: string;
}

const LoadingIndicatorModal: React.FC<ILoadingIndicatorModalProps> = (props) => (
  <Modal { ...props } onModalClose={ () => {} } id="loadingModal">
    <div className="flex flex-col gap-6 items-center">
      <div className={ style.loadingIcon } >
        <FontAwesomeIcon icon={ faSync } className="animate-spin" />
      </div>
      <h4>{ props.loadingText || "Loading..." }</h4>
    </div>
  </Modal>
);

export default LoadingIndicatorModal;
