/* eslint-disable max-len */
import React from "react";
import { ISVG } from "./constants/ISVG";
import { SVGIconColors } from "./constants/SVGIconColors";

const WriteIcon: React.FC<ISVG> = (props) => {
  const {
    style = {},
    fill = SVGIconColors.pink,
    width = "33",
    className = "",
    height = "40",
    viewBox = "0 0 33 40",
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fill}
        d="M31.965 36a4 4 0 01-4 4H4.61a4 4 0 01-4-4V4.13a4 4 0 014-4h19.833v1.91H2.534V38.09h27.507V7.42h1.924V36z"
      ></path>
      <path
        fill={fill}
        d="M30.959 8.373h-7.478V.521l.574-.263a.998.998 0 011.044.132l6.118 5.92a1.83 1.83 0 01.398 2.063h-.656zm-5.554-1.909h3.236l-3.236-3.123v3.123zM24.53 14.447H8.089v1.909h16.443v-1.91zM16.31 19.87H8.087v1.909h8.221v-1.91zM24.53 28.46H8.089v1.909h16.443v-1.91z"
      ></path>
    </svg>
  );
};

export default WriteIcon;
