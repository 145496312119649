import React, { useState } from "react";

export type ClientPortalNavItem = {
  icon: string;
  label: string;
  route: string;
  active?: boolean;
  isAdviser?: boolean;
  hide?: boolean;
};

interface IClientPortalContext {
  navItems: Array<ClientPortalNavItem>;
  setActiveNavItem?: (stepIndex: number) => void;
  messageList: Array<MemberNotifications>;
  setMessageList?: (messageList: MemberNotifications[]) => void;
}

export const ClientPortalNavLabel = {
  Dashboard: "Dashboard",
  CheckYourInvestments: "Check your investments",
  DoYourAdmin: "Do your admin",
  MoneyTips: "Money tips",
  YourAdviser: "Your adviser",
  Inbox: "Inbox",
  Help: "Help",
};

export const defaultNavItems: Array<ClientPortalNavItem> = [
  {
    icon: "/assets/images/dashboard-icon.svg",
    label: ClientPortalNavLabel.Dashboard,
    route: "dashboard",
  },
  {
    icon: "/assets/images/chart-line.svg",
    label: ClientPortalNavLabel.CheckYourInvestments,
    route: "investments",
  },
  {
    icon: "/assets/images/file.svg",
    label: ClientPortalNavLabel.DoYourAdmin,
    route: "admin",
  },
  {
    icon: "/assets/images/money.svg",
    label: ClientPortalNavLabel.MoneyTips,
    route: "money-tips",
  },
  {
    icon: "/assets/images/human_bulb.svg",
    label: ClientPortalNavLabel.YourAdviser,
    route: "your-adviser",
    isAdviser: true,
  },
  {
    icon: "/assets/images/human_bulb.svg",
    label: ClientPortalNavLabel.Inbox,
    route: "inbox",
    hide: true,
  },
  {
    icon: "/assets/images/human_bulb.svg",
    label: ClientPortalNavLabel.Help,
    route: "help",
    hide: true,
  },
];

export const ClientPortalContext = React.createContext<IClientPortalContext>({
  navItems: defaultNavItems,
  messageList: [],
});

const ClientPortalNavProvider: React.FC = ({ children }) => {
  const [navItems, setNavItems] = useState<ClientPortalNavItem[]>(defaultNavItems);
  const [messageList, setMessageList] = useState<MemberNotifications[]>([]);

  const setActiveNavItem = (stepIndex: number) => {
    setNavItems((prev) =>
      prev.map((el, index) => ({
        ...el,
        active: index === stepIndex,
      }))
    );
  };

  return (
    <ClientPortalContext.Provider
      value={{
        navItems,
        setActiveNavItem,
        messageList,
        setMessageList,
      }}
    >
      {children}
    </ClientPortalContext.Provider>
  );
};

export default ClientPortalNavProvider;
