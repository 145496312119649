/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, useEffect } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faTimes,
  faExclamationTriangle,
  faCheckCircle,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import style from "./toast.module.css";

export enum ToastVariant {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  ALERT = "ALERT",
  DOWNLOAD = "DOWNLOAD",
}

export interface ToastProps {
  message: string;
  onClose?: () => void;
  variant?: ToastVariant;
  active?: boolean,
  element?: ReactElement,
  timeout?: number,
}

export const Toast: React.FC<ToastProps> = (props) => {
  const {
    variant,
    message,
    onClose,
    active,
    element,
    timeout = 5000,
  } = props;

  let iconLeft = faSpinner;
  switch (variant) {
  case ToastVariant.SUCCESS:
    iconLeft = faCheckCircle;
    break;
  case ToastVariant.DOWNLOAD:
    iconLeft = faDownload;
    break;
  case ToastVariant.ALERT:
    iconLeft = faExclamationTriangle;
    break;
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (timeout && active) {
      timeoutId = setTimeout(() => {
        onClose && onClose();
      }, timeout);
    }
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [timeout, active]);

  return (
    <div
      className={ classNames(style.container, {
        [style["pink"]]: variant === ToastVariant.SUCCESS,
        [style["orange"]]: variant === ToastVariant.ALERT,
        [style["teal"]]: variant === ToastVariant.IN_PROGRESS,
        [style["active"]]: active,
      }) }
      data-testid="toastContainer"
    >
      <span className={ classNames(style.icLeft, {
        "animate-spin": variant === ToastVariant.IN_PROGRESS,
        [style["pink"]]: variant === ToastVariant.SUCCESS,
        [style["midGrey"]]: variant === ToastVariant.DOWNLOAD,
        [style["orange"]]: variant === ToastVariant.ALERT,
      }) }>
        <FontAwesomeIcon icon={ iconLeft } />
      </span>
      <div className="mx-2">
        <p>{ message }</p>
        { element }
      </div>
      <button
        data-testid="btnClose"
        className="w-5"
        onClick={ () => onClose && onClose() }>
        <FontAwesomeIcon
          icon={ faTimes }
          color="#6B6B6B" />
      </button>
    </div>
  );
};

export default Toast;
