/* eslint-disable max-len */
import React from "react";
import { ISVG } from "./constants/ISVG";
import { SVGIconColors } from "./constants/SVGIconColors";

const QuestionIcon: React.FC<ISVG> = (props) => {
  const {
    style = {},
    fill = SVGIconColors.pink,
    width = "162",
    className = "",
    height = "162",
    viewBox = "0 0 162 162",
  } = props;

  return (
    <svg
      width={ width }
      height={ height }
      viewBox={ viewBox }
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={ className }
      style={ style }
    >
      <circle cx="81" cy="131" r="7" fill={ fill }/>
      <path
        d="M81 88V85H78V88H81ZM81.5513 24.0048L81.603 21.0052L81.5513 24.0048ZM62.7301 29.7282L64.4429 32.1911L62.7301 29.7282ZM50.8135 45.3804L47.9835 44.3848L50.8135 45.3804ZM81 91C88.3501 91 95.5136 88.6861 101.475 84.3863L97.9648 79.5201C93.0256 83.0827 87.0901 85 81 85V91ZM101.475 84.3863C107.436 80.0866 111.892 74.0192 114.212 67.0447L108.518 65.1513C106.596 70.9302 102.904 75.9574 97.9648 79.5201L101.475 84.3863ZM114.212 67.0447C116.531 60.0702 116.596 52.5425 114.397 45.5291L108.672 47.3241C110.494 53.1352 110.44 59.3725 108.518 65.1513L114.212 67.0447ZM114.397 45.5291C112.198 38.5156 107.847 32.3724 101.961 27.9706L98.3675 32.7757C103.245 36.4229 106.85 41.5129 108.672 47.3241L114.397 45.5291ZM101.961 27.9706C96.0746 23.5688 88.9519 21.1318 81.603 21.0052L81.4996 27.0043C87.5887 27.1092 93.4903 29.1285 98.3675 32.7757L101.961 27.9706ZM81.603 21.0052C74.254 20.8786 67.0516 23.0688 61.0173 27.2652L64.4429 32.1911C69.4428 28.7141 75.4104 26.8994 81.4996 27.0043L81.603 21.0052ZM61.0173 27.2652C54.9829 31.4616 50.4228 37.4512 47.9835 44.3848L53.6435 46.376C55.6646 40.631 59.443 35.6682 64.4429 32.1911L61.0173 27.2652ZM47.9835 44.3848C45.5443 51.3183 45.3498 58.8438 47.4276 65.8941L53.1828 64.1979C51.4613 58.3563 51.6224 52.1209 53.6435 46.376L47.9835 44.3848ZM78 88V115.5H84V88H78Z"
        fill={ fill }/>
    </svg>

  );
};

export default QuestionIcon;
