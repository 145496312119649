/* eslint-disable max-len */
import React from "react";
import { ISVG } from "./constants/ISVG";
import { SVGIconColors } from "./constants/SVGIconColors";

const MessageIcon: React.FC<ISVG> = (props) => {
  const {
    style = {},
    fill = SVGIconColors.pink,
    width = "56",
    className = "",
    height = "56",
    viewBox = "0 0 56 56",
  } = props;

  return (
    <svg
      width={ width }
      height={ height }
      viewBox={ viewBox }
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={ className }
      style={ style }
    >
      <path d="M11.775 38.5919L12.1757 37.8978L11.5856 37.3555C8.60537 34.6171 6.87659 31.1762 6.87659 27.4815C6.87659 23.2361 9.16272 19.3172 13.008 16.4258C16.8537 13.5341 22.212 11.7161 28.1729 11.7161C34.1338 11.7161 39.4921 13.5341 43.3378 16.4258C47.183 19.3172 49.4692 23.2361 49.4692 27.4815C49.4692 31.7269 47.183 35.6458 43.3378 38.5372C39.4921 41.4289 34.1338 43.2469 28.1729 43.2469C24.7136 43.2469 21.4532 42.6344 18.5727 41.5522L18.2723 41.4393L17.9622 41.5224L8.64108 44.02L11.775 38.5919Z"
        stroke={ fill } strokeWidth="2"/>
    </svg>

  );
};

export default MessageIcon;
