import React, { useEffect, useState } from "react";
const PageLoading: React.FC = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 20);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return show ? <div>Loading...</div> : null;
};

export default PageLoading;
