/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useRef, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import useOutsideClick from "../../hooks/useOutsideClick";
import HorizontalSeparator from "../horizontalSeparator";
import style from "./modal.module.css";

export interface IModalProps {
  className?: string;
  isVisible?: boolean;
  onModalClose?: () => any;
  title?: string;
  id?: string;
  disableOutsideClick?: boolean;
  hideSeparator?: boolean;
  backdropClassName?: string;
  containerRef?: any;
  adjustableWidth?: boolean;
}

const RootModal: React.FC<IModalProps> = (props) => {
  const {
    children,
    className,
    isVisible,
    onModalClose,
    title,
    hideSeparator,
    backdropClassName,
    adjustableWidth,
    disableOutsideClick,
  } = props;
  const wrapperRef = useRef(null);

  useEffect(() => {
    const body = document.querySelector("body") as HTMLBodyElement;
    body.style.overflow = isVisible ? "hidden" : "auto";
  }, [isVisible]);

  const handleModalClose = useCallback(() => {
    if (wrapperRef && wrapperRef.current && onModalClose) {
      onModalClose();
    }
  }, [onModalClose]);

  const handleClickOutside = useCallback(() => {
    if (wrapperRef && wrapperRef.current && onModalClose && !disableOutsideClick) {
      onModalClose();
    }
  }, [onModalClose, disableOutsideClick]);

  useOutsideClick(wrapperRef, handleClickOutside);

  if (!isVisible) return null;

  return (
    <div>
      <div
        className={classNames(style.backdrop, backdropClassName)}
        role="button"
        tabIndex={0}
        id="modal-backdrop"
        onClick={handleModalClose}
      >
        <div className={style.contentCentered}>
          <div
            ref={wrapperRef}
            className={classNames(
              style.content,
              className,
              adjustableWidth ? "md:max-w-5xl" : "max-w-screen-xs md:max-w-4xl"
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
            tabIndex={0}
            role="button"
          >
            {title && (
              <>
                <div className="flex justify-between">
                  <h4 className="mr-2 font-bold">{title}</h4>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-lg text-mono-midGrey cursor-pointer"
                    onClick={handleModalClose}
                  />
                </div>
                {!hideSeparator && <HorizontalSeparator />}
              </>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
const Modal: React.FC<IModalProps> = (props) => {
  const isTestEnv = process.env.NODE_ENV === "test";

  const rootElement = document.getElementById("root");
  return !isTestEnv && rootElement ? (
    ReactDOM.createPortal(<RootModal {...props} />, rootElement)
  ) : (
    <RootModal {...props} />
  );
};

export default Modal;
