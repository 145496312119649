/* eslint-disable max-len */
import React from "react";
import { ISVG } from "./constants/ISVG";
import { SVGIconColors } from "./constants/SVGIconColors";

const UploadIcon: React.FC<ISVG> = (props) => {
  const {
    style = {},
    fill = SVGIconColors.pink,
    width = "28",
    className = "",
    height = "39",
    viewBox = "0 0 28 39",
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fill}
        d="M14.11.449c.012 0 .023.006.03.015l7.527 10.981a1 1 0 01-.825 1.566h-2.557v14.872H10.01V13.01h-2.6a1 1 0 01-.826-1.564L14.081.464a.035.035 0 01.03-.015zm5.523 10.959l-5.504-8.052-5.504 8.052h3.033v14.835h4.98V11.37h3.032l-.037.038zM27.757 37.059a1 1 0 01-1 1H1.463a1 1 0 01-1-1v-5.993a1 1 0 011-1h25.294a1 1 0 011 1v5.993zm-25.646-1.64a1 1 0 001 1H25.11a1 1 0 001-1v-2.713a1 1 0 00-1-1h-22a1 1 0 00-1 1v2.713z"
      ></path>
      <circle cx="23.287" cy="34.059" r="1.176" fill={fill}></circle>
    </svg>
  );
};

export default UploadIcon;
