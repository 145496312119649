import { Auth } from "aws-amplify";
import { navigate } from "@reach/router";
import { AZURE_ID_KEY, NUM_ACTIVE_USERS } from "../constants/localStorageKey";

// Configuration setting for AWS Cognito
const config = {
  cognito: {
    REGION: process.env.REACT_APP_AWS_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_AWS_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_AWS_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    FEDERATED_IDENTITY_PROVIDER: process.env.REACT_APP_AWS_FEDERATED_IDENTITY_PROVIDER,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_AWS_APP_REGION,
    URL: process.env.REACT_APP_API_URL,
  },
};

const AUTH_CONFIG = {
  mandatorySignIn: true,
  region: config.cognito.REGION,
  userPoolId: config.cognito.USER_POOL_ID,
  identityPoolId: config.cognito.IDENTITY_POOL_ID,
  userPoolWebClientId: config.cognito.APP_CLIENT_ID,
};

let afterAuthRedirect = process.env.REACT_APP_AWS_REDIRECT_URL || "http://localhost:3000/";

// This should be the same as the ClearView identity provider's Authorization endpoint in
// the Cognito user pool, but with the final "authorize" replaced with "logout".
const clearviewLogoutURL = process.env.REACT_APP_CLEARVIEW_LOGOUT_URL;
const OAUTH_CONFIG = {
  domain: process.env.REACT_APP_USER_POOL_DOMAIN,
  scope: ["openid", "aws.cognito.signin.user.admin", "email", "profile"],
  redirectSignIn: afterAuthRedirect,

  // Cognito doesn't support logging the user out of their session with the upstream
  // identity provider. As a workaround we setup the redirectSignOut URI such that
  // it sends the user to the upstream identity providers logout URL, and after that back
  // to the app. (Usually we would just setup the redirectSignOut URI to send the user
  // directly back to the app).
  // The redirectSignOut must match an allowed signout URL configured in Cognito.
  redirectSignOut: `${clearviewLogoutURL}?post_logout_redirect_uri=${encodeURIComponent(afterAuthRedirect)}`,
  responseType: "code",
};

const API_CONFIG = {
  endpoints: [
    {
      name: "ClearviewAPI",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
      custom_header: async () => {
        try {
          return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() };
        } catch {
          return {};
        }
      },
    },
  ],
};

const authenticate = async (username: string, password: string) => {
  await Auth.signIn({ username, password });
};

const logout = async () => {
  // need to set the key to empty if you want to log user out
  localStorage.setItem(AZURE_ID_KEY, "");
  localStorage.setItem(NUM_ACTIVE_USERS, "");
  await Auth.signOut();
};

const onClickSwitchUser = () => {

  // we want to clear the current Id when our user wants to switch to another user
  localStorage.setItem(AZURE_ID_KEY, "");

  navigate("/");
};

const authenticateFederated = async () =>
  await Auth.federatedSignIn({
    customProvider: config.cognito.FEDERATED_IDENTITY_PROVIDER || "ClearView",
  });

export { AUTH_CONFIG, OAUTH_CONFIG, API_CONFIG, authenticate, authenticateFederated, logout, onClickSwitchUser };
