import React, { useEffect } from "react";

const NO_CHECK_LISTS = ["dropdown"];

const useOutsideClick = (ref: React.RefObject<any>, callback: () => void) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      let classNameOfTarget = "";
      try {
        classNameOfTarget = ((e.target as Element)?.className || "")?.toLowerCase();
        // eslint-disable-next-line no-empty
      } catch (_) {}

      if (
        ref &&
        ref.current &&
        !ref.current.contains(e.target) &&
        !NO_CHECK_LISTS.some((el) => classNameOfTarget?.includes(el))
      ) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export default useOutsideClick;
