import { RouteComponentProps, Router, navigate, globalHistory } from "@reach/router";
import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useIdleTimer } from "react-idle-timer";
import { Auth, Amplify } from "aws-amplify";

import "./index.css";
import PageLoading from "./common/modules/PageLoading";
import reportWebVitals from "./reportWebVitals";
import { AUTH_CONFIG, API_CONFIG, OAUTH_CONFIG, logout } from "./common/aws/amplifyConfig";
import ToastProvider from "./adviserPortal/context/useToast";

import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import { SegmentIncludeScript } from "./utils/setupSegment";
import { AZURE_ID_KEY, FORCE_TO_LOGOUT, NUM_ACTIVE_USERS } from "./common/constants/localStorageKey";
import { defaultNavMenuItems as listRoutesForClientView } from "./adviserPortal/context/useClientViewNavMenu";
import { defaultNavItems as listRoutesForAdviserPortal } from "./adviserPortal/context/useAdviserNav";
import { eAppDefaultStages } from "./eApplication/context/useEAppNav";
import { defaultNavItems as listRoutesForClientPortal } from "./clientPortal/context/useClientPortalNav";
import LoadingIndicatorModal from "./common/layouts/loadingIndicatorModal";

// page lazy imports
const App = React.lazy(() => import("./App")) as React.FC<RouteComponentProps>;
const EApp = React.lazy(() => import("./eApplication/pages")) as React.FC<RouteComponentProps>;
const FinancialReviewTBC = React.lazy(() => import("./financialReviewTBC/pages")) as React.FC<RouteComponentProps>;
const AdviserApp = React.lazy(() => import("./adviserPortal/pages")) as React.FC<RouteComponentProps>;
const Login = React.lazy(() => import("./auth/login")) as React.FC<RouteComponentProps>;
const ClientPortal = React.lazy(() => import("./clientPortal/pages")) as React.FC<RouteComponentProps>;

// configuring aws amplify
Amplify.configure({
  Auth: AUTH_CONFIG,
  API: API_CONFIG,
  oauth: OAUTH_CONFIG,
});

const TIME_OUT = 1000 * 60 * 60;
let startTime = Date.now();

const otherRoutesPortal = [
  {
    icon: null,
    label: "Inbox",
    route: "inbox",
  },
  {
    icon: null,
    label: "Help",
    route: "help",
  },
];

const setAppTitle = (route: string) => {
  document.title = "Login | ClearView";
  if (route.includes("/adviser-portal")) {
    document.title = "Dashboard | Adviser Portal";
  }
  listRoutesForClientView.forEach((item) => {
    if (route.includes(`/adviser-portal/client-view/${item.route}`)) {
      document.title = `Client ${item.label} | Adviser Portal`;
    }
  });
  if (route.includes("/adviser-portal/client-view/client-admin")) {
    document.title = "Client admin | Adviser Portal";
  }
  [...listRoutesForAdviserPortal, ...otherRoutesPortal].forEach((item) => {
    if (route.includes(`/adviser-portal/${item.route}`)) {
      document.title = `${item.label} | Adviser Portal`;
    }
  });
  if (route.includes("/e-app")) {
    document.title = "Personal details | eApplication";
  }
  eAppDefaultStages.forEach((item) => {
    item.steps.forEach((step) => {
      if (route.includes(`/e-app/${step.route}`)) {
        document.title = `${step.label} | eApplication`;
      }
    });
  });
  if (route.includes("/client-portal")) {
    document.title = "Dashboard | ClearView";
  }
  if (route.includes("/financial-review-TBC")) {
    document.title = "My Tools | Financial Review TBC";
  }
  [...listRoutesForClientPortal, ...otherRoutesPortal].forEach((item) => {
    if (route.includes(`/client-portal/${item.route}`)) {
      document.title = `${item.label} | ClearView`;
    }
  });
  if (route.includes("/adviser-portal/e-app")) {
    document.title = "eApplication home | Adviser Portal";
  }
};

const Container = () => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // set app titles when accessing the links in the first time.
    setAppTitle(window.location.pathname);

    globalHistory.listen((action) => {
      window.analytics.page();
      setAppTitle(action.location.pathname);
    });
  }, []);

  const onAction = () => {
    // in case of your browser wakes up from sleeping mode
    if (Date.now() - startTime >= TIME_OUT) {
      // in case of your account has been logged out from another tab
      const azureId = localStorage.getItem(AZURE_ID_KEY);

      if (!azureId) {
        navigate("/");
      } else {
        setLoading(true);
        onIdle();
      }
    }

    startTime = Date.now();
  };

  const onIdle = () => {
    Auth.currentSession()
      .then((s) => {
        if (s) {
          logout();
        }
      })
      .catch(() => {
        // it should be redirected into the login page
        // if you open many tabs at the same time and one of them had been redirected
        // the others do not have current session so that it can do the function logout()
        navigate("/");
      });
  };

  useIdleTimer({ onIdle, timeout: TIME_OUT, onAction });

  const onBeforeUnLoad = () => {
    const activeUsersBeforeUnload = Number(localStorage.getItem(NUM_ACTIVE_USERS));
    const activeUsersAfterUnload = activeUsersBeforeUnload > 0 ? activeUsersBeforeUnload - 1 : 0;
    const azureId = localStorage.getItem(AZURE_ID_KEY);

    localStorage.setItem(NUM_ACTIVE_USERS, activeUsersAfterUnload.toString());

    if (!activeUsersAfterUnload && azureId) {
      localStorage.setItem(FORCE_TO_LOGOUT, "1");
    }
  };

  useEffect(() => {
    const entry = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;
    const activeUsers = Number(localStorage.getItem(NUM_ACTIVE_USERS));

    if (entry?.type === "navigate" && !activeUsers) {
      localStorage.setItem(AZURE_ID_KEY, "");
    }

    window.addEventListener("unload", onBeforeUnLoad);
    return () => {
      window.removeEventListener("unload", onBeforeUnLoad);
    };
  }, []);

  useEffect(() => {
    Auth.currentSession().then((s) => {
      const activeUsers = Number(localStorage.getItem(NUM_ACTIVE_USERS));
      if (s) {
        localStorage.setItem(NUM_ACTIVE_USERS, (activeUsers + 1).toString());
      }
    });
  }, []);

  return (
    <React.StrictMode>
      <Suspense fallback={<PageLoading />}>
        <ToastProvider>
          <Router>
            {SegmentIncludeScript}
            <App path="/" />
            <EApp path="e-app/*" />
            <FinancialReviewTBC path="financial-review-TBC/*" />
            <AdviserApp path="adviser-portal/*" />
            <Login path="/login" />
            <ClientPortal path="client-portal/*" />
          </Router>
          <LoadingIndicatorModal isVisible={loading} />
        </ToastProvider>
      </Suspense>
    </React.StrictMode>
  );
};

ReactDOM.render(<Container />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
