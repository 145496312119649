import React, { ReactElement, useState } from "react";
import { Toast, ToastVariant } from "../../common/components/toast";

interface IToastContext {
  showToast: (options: {
    message: string,
    variant: ToastVariant,
    timeout?: number
    element?: ReactElement,
  }) => void;
  dismiss: () => void;
}

type StateType = {
  isActive: boolean,
  message: string,
  variant: ToastVariant,
  timeout?: number,
  element?: ReactElement,
}

export const ToastContext = React.createContext<IToastContext>({
  showToast: () => {},
  dismiss: () => {},
});

const ToastProvider: React.FC = ({ children })  => {
  const [state, setState] = useState<StateType>({
    isActive: false,
    message: "",
    variant: ToastVariant.SUCCESS,
  });

  const showToast = ({ message, variant, timeout, element }: {
    message: string, variant: ToastVariant, timeout?: number, element?: ReactElement }) => {
    setState({
      isActive: true,
      message,
      variant,
      timeout,
      element,
    });
  };

  const dismiss = ()  => {
    setState((prev) => ({
      ...prev,
      isActive: false,
      element: undefined,
    }));
  };

  return <ToastContext.Provider value={ {
    showToast,
    dismiss,
  } }>
    { children }
    <Toast
      message={ state.message }
      variant={ state.variant }
      active={ state.isActive }
      onClose={ () => {
        setState((prev) => ({
          ...prev,
          isActive: false,
          element: undefined,
        }));
      } }
      timeout={ state.timeout }
      element={ state.element }
    />
  </ToastContext.Provider>;
};

export default ToastProvider;
