/* eslint-disable max-len */
import React from "react";
import { ISVG } from "./constants/ISVG";
import { SVGIconColors } from "./constants/SVGIconColors";

const MenuIcon: React.FC<ISVG> = (props) => {
  const {
    style = {},
    fill = SVGIconColors.pink,
    width = "38",
    className = "",
    height = "40",
    viewBox = "0 0 38 40",
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fill={fill}
        d="M37.213 5.33H20.097v2.017h17.116V5.33zM4.362 5.376H.037v2.017h4.325V5.376zM37.26 18.991H33.9v2.018h3.36V18.99zM18.855 18.991H.037v2.018h18.818V18.99zM37.26 32.653H19.544v2.017H37.26v-2.017zM3.35 32.653H.037v2.017H3.35v-2.017zM26.677 25.868c-3.267 0-5.936-2.659-5.936-5.868 0-3.21 2.67-5.868 5.936-5.868 3.267 0 5.935 2.659 5.935 5.868 0 3.21-2.668 5.868-5.935 5.868zm0-9.765A3.9 3.9 0 0022.766 20a3.9 3.9 0 003.91 3.897A3.9 3.9 0 0030.589 20a3.9 3.9 0 00-3.911-3.897zM11.954 12.252c-3.267 0-5.936-2.659-5.936-5.868 0-3.209 2.669-5.913 5.936-5.913 3.266 0 5.889 2.659 5.889 5.868 0 3.209-2.669 5.868-5.89 5.868v.045zm0-9.764a3.9 3.9 0 00-3.911 3.896 3.9 3.9 0 003.91 3.897 3.9 3.9 0 003.911-3.897 3.9 3.9 0 00-3.91-3.896zM11.31 39.53c-3.267 0-5.89-2.66-5.89-5.869 0-3.209 2.669-5.868 5.89-5.868 3.22 0 5.935 2.66 5.935 5.868 0 3.21-2.669 5.868-5.936 5.868zm0-9.765a3.9 3.9 0 00-3.912 3.896 3.9 3.9 0 003.911 3.897 3.9 3.9 0 003.911-3.897 3.9 3.9 0 00-3.91-3.896z"
      ></path>
    </svg>
  );
};

export default MenuIcon;
