import React, { useState, FunctionComponent } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import {
  faBullhorn,
  faClipboardCheck,
  faHandHoldingHeart,
  faHandHoldingUsd,
  faHands,
  faIdCard,
  faLeaf,
  faMarker,
  faPiggyBank,
  faWrench,
  faBell,
  faHandPointUp,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { SectionKey, Routes } from "../context/eAppState";
import { ReactComponent as HammerIc } from "../../assets/images/hammer-icon.svg";
import { ReactComponent as InvestmentsIc } from "../../assets/images/chartBarV-icon.svg";
import { ReactComponent as ListIc } from "../../assets/images/list-icon.svg";
import { ReactComponent as HandHoldHeartIc } from "../../assets/images/handholdheart-icon.svg";
import { ReactComponent as WriteIc } from "../../assets/images/write-icon.svg";
import { ReactComponent as BulbIc } from "../../assets/images/bulb-icon.svg";

export type EAppNavStep = {
  icon: IconDefinition;
  label: string;
  route: Routes;
  active?: boolean;
  section: SectionKey;
};

export type EAppNavStage = {
  label: string;
  steps: Array<EAppNavStep>;
  active?: boolean;
  icon: FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

interface IEAppNavContext {
  stages: Array<EAppNavStage>;
  setActiveStage?: (stageIndex: number, stepIndex: number) => void;
}

export const eAppDefaultStages: Array<EAppNavStage> = [
  {
    label: "Product setup",
    steps: [
      {
        icon: faUser,
        label: "Personal details",
        active: true,
        route: "personal-details",
        section: "productSetup",
      },
      {
        icon: faHandPointUp,
        label: "Product selection",
        route: "product-selection",
        section: "productSelection",
      },
      {
        icon: faMarker,
        label: "TFN declaration",
        route: "tfn-declaration",
        section: "tfnDeclaration",
      },
    ],
    active: true,
    icon: HammerIc,
  },
  {
    label: "Investments and contributions",
    icon: InvestmentsIc,
    steps: [
      {
        icon: faPiggyBank,
        label: "Rollovers",
        route: "rollovers",
        section: "rollovers",
      },
      {
        icon: faWrench,
        label: "Set up contributions",
        route: "set-up-contributions",
        section: "setUpContributions",
      },
      {
        icon: faLeaf,
        label: "Investment instructions",
        route: "investment-instruction",
        section: "investmentInstruction",
      },
    ],
  },
  {
    label: "Pension and identification",
    icon: WriteIc,
    steps: [
      {
        icon: faHandHoldingUsd,
        label: "Set up pension",
        route: "pension",
        section: "setUpPension",
      },
      {
        icon: faIdCard,
        label: "Identification",
        route: "identification",
        section: "identification",
      },
    ],
  },
  {
    label: "Beneficiaries",
    icon: HandHoldHeartIc,
    steps: [
      {
        icon: faHands,
        label: "Reversionary beneficiary",
        route: "beneficiaries",
        section: "reversionaryBeneficiary",
      },
      {
        icon: faHandHoldingHeart,
        label: "Death benefit",
        route: "death-benefit",
        section: "deathBenefit",
      },
    ],
  },
  {
    label: "Adviser admin",
    icon: ListIc,
    steps: [
      {
        icon: faBell,
        label: "Adviser service fees",
        route: "adviser-service-fees",
        section: "adviserServiceFees",
      },
      {
        icon: faBullhorn,
        label: "Adviser declaration",
        route: "adviser-declaration",
        section: "adviserDeclaration",
      },
    ],
  },
  {
    label: "Review and submit",
    icon: BulbIc,
    steps: [
      {
        icon: faClipboardCheck,
        label: "Summary",
        route: "summary",
        section: "reviewAndSubmit",
      },
    ],
  },
];

export const EAppNavContext = React.createContext<IEAppNavContext>({
  stages: eAppDefaultStages,
});

const EAppNavProvider: React.FC = ({ children }) => {
  const [stages, setStages] = useState<EAppNavStage[]>(eAppDefaultStages);

  /** toggles the active state on stages and steps */
  const setActiveStage = (stageIndex: number, stepIndex: number) => {
    if (!stages[stageIndex] || !stages[stageIndex].steps[stepIndex]) {
      return;
    }

    const stagesCopy = stages.map((stage, index) => {
      const isStageActive = !!(index === stageIndex);
      return {
        ...stage,
        active: isStageActive,
        steps: stage.steps.map((step, index) => {
          const isStepActive = !!(index === stepIndex && isStageActive);
          return {
            ...step,
            active: isStepActive,
          };
        }),
      };
    });

    setStages(stagesCopy);
  };

  return (
    <EAppNavContext.Provider
      value={{
        stages,
        setActiveStage,
      }}
    >
      {children}
    </EAppNavContext.Provider>
  );
};

export default EAppNavProvider;
