import React, { useState } from "react";

import {
  FluctuateGraphIcon,
  MenuIcon,
  PersonIcon,
  RolloversIcon,
  TransactionIcon,
  UploadIcon,
  WatchIcon,
  WriteIcon,
} from "../../common/icons";
import { ISVG } from "../../common/icons/constants/ISVG";

export type ClientViewNavMenuItem = {
  ic: React.FC<ISVG>;
  label: string;
  route: string;
  active?: boolean;
};

interface IClientViewNavMenuContext {
  navMenuItems: Array<ClientViewNavMenuItem>;
  setActiveNavMenuItem?: (stepIndex: number) => void;
}

export const defaultNavMenuItems: Array<ClientViewNavMenuItem> = [
  {
    ic: PersonIcon,
    label: "Profile",
    route: "profile",
    active: true,
  },
  {
    ic: TransactionIcon,
    label: "Transactions",
    route: "transactions",
  },
  {
    ic: FluctuateGraphIcon,
    label: "Investments",
    route: "investments",
  },
  {
    ic: WatchIcon,
    label: "Products",
    route: "products",
  },
  {
    ic: WriteIcon,
    label: "Reports",
    route: "reports",
  },
  {
    ic: RolloversIcon,
    label: "Rollovers",
    route: "rollovers",
  },
  {
    ic: UploadIcon,
    label: "Upload documents",
    route: "upload-documents",
  },
  {
    ic: MenuIcon,
    label: "Client admin",
    route: "client-admin",
  },
];

export const ClientViewNavMenuContext = React.createContext<IClientViewNavMenuContext>({
  navMenuItems: defaultNavMenuItems,
});

const ClientViewNavMenuProvider: React.FC = ({ children }) => {
  const [navMenuItems, setNavMenuItems] = useState<ClientViewNavMenuItem[]>(defaultNavMenuItems);

  const setActiveNavMenuItem = (stepIndex: number) => {
    setNavMenuItems((prev) =>
      prev.map((el, index) => ({
        ...el,
        active: index === stepIndex,
      }))
    );
  };

  return (
    <ClientViewNavMenuContext.Provider
      value={{
        navMenuItems,
        setActiveNavMenuItem,
      }}
    >
      {children}
    </ClientViewNavMenuContext.Provider>
  );
};

export default ClientViewNavMenuProvider;
